import * as React from "react"
import Navbar from "../components/navbar";
import SiteHat from '../components/sitehat'
import Card from '../components/card'

interface Node<Type> {
  node: Type;
}

export default function HomePage() {

  return (
      <main>
        <SiteHat title="Home" />
        <Navbar/>
        <div className="container-fluid">

            <Card title="Elijah"/>
            <Card title="Grant"/>


        </div>
      </main>
  )
}