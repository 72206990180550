import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import { StaticQuery, graphql } from "gatsby";

const SiteNav = () => {

    return (
        <StaticQuery
            query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title,
              siteUrl
            }
          }
        }
      `}
            render={data => (
                <Navbar bg="light" expand="lg">
                    <Container>
                    <Navbar.Brand href={data.site.siteMetadata.siteUrl}>{data.site.siteMetadata.title}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="top-navbar-nav" />
                    <Navbar.Collapse id="top-navbar-nav">
                    <Nav className="me-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                    </Container>
                </Navbar>
            )}
        />

    )
}

export default SiteNav